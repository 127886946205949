<template>
  <b-sidebar
    id="add-new-production-target-sidebar"
    :visible="isAddNewProductionTargetSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-production-target-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ actionType == 0 ? " Add New Production Target" : actionType == 1 ? " Update Production Target" : "Delete ProductionTarget"}}
         
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(submitForm)"
          @reset.prevent="resetForm"
        >
        
          <b-form-group label="KPI Name">
            <v-select
              id="productionTarget-kpiName"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              v-model="currentKpiName"
              :options="kpiNames"
              :disabled="actionType==2"
              class="select-size-lg">
            </v-select>
          </b-form-group>


          <validation-provider
            #default="validationContext"
            name="Value"
            rules="required"
          >
            <b-form-group
              label="Value"
              label-for="productionTarget-value"
            >
              <b-form-input
                id="productionTarget-value"
                v-model="currentValue"
                :disabled="actionType==2"
                type="number"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Value"
              />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
          </validation-provider>

          <b-form-group label="Production Location Type">
            <v-select
              id="productionLocationId"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              v-model="currentproductionLocation"
              label="name"
              :options="productionLocations"
              v-on:input="changeProductionLocationType"
              :disabled="actionType==2"
              class="select-size-lg">
              <template #option="{ id, name }">
                  <span> {{ name }}</span><br />
                  <small class="text-muted"> {{ id }} </small>
              </template>
            </v-select>
          </b-form-group>

          <b-form-group v-if="isWorkcenter" :label="currentproductionLocation.name">
            <v-select
              id="WorkcenterId"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              v-model="currentWorkcenter"
              label="name"
              :options="workcenterList"
              v-on:input="changeProductionLocation"
              :disabled="actionType==2"
              class="select-size-lg">
              <template #option="{ id, name }">
                  <span> {{ name }}</span><br />
                  <small class="text-muted"> {{ id }} </small>
              </template>
            </v-select>
          </b-form-group>

          <b-form-group v-if="isSite" label="Site">
            <v-select
              id="SiteId"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              v-model="currentSite"
              label="name"
              :options="sites"
              v-on:input="changeProductionLocation"
              :disabled="actionType==2"
              class="select-size-lg">
              <template #option="{ id, name }">
                  <span> {{ name }}</span><br />
                  <small class="text-muted"> {{ id }} </small>
              </template>
            </v-select>
          </b-form-group>

          <b-form-group v-if="isSpec" label="Spec">
            <v-select
              id="SiteId"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              v-model="currentSpec"
              label="specNo"
              :options="specList"
              :disabled="actionType==2"
              class="select-size-lg">
              <template #option="{ id, specNo }">
                  <span> {{ specNo }}</span><br />
                  <small class="text-muted"> {{ id }} </small>
              </template>
            </v-select>
          </b-form-group>

          <b-form-checkbox 
            class="mt-2"
            :disabled="actionType==2"
            id="input-isActive"
            v-model="currentIsActive"
          >
            IsActive
          </b-form-checkbox>
     
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              v-if="actionType != 2"
              class="mr-2"
              type="submit"
            >
             {{ currentProductionTarget==null|| currentProductionTarget.id==null? 'Add':'Update'}} 
            </b-button>
             <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              class="mr-2"
              v-if="currentProductionTarget!=null&& currentProductionTarget.id!=null && actionType == 2"
              variant="outline-danger"
              @click="deleteProductionTarget"
            >
              Delete
            </b-button>

            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormSelect, BFormCheckbox
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {inject,toRefs, ref,watch } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BFormSelect,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
  },
  created() {
    this.fetchKPINames();
    this.fetchProductionLocations();
    this.fetchSites();
    this.fetchWorkCenters();
  },
   methods:{
    fetchSpecByWorkcenter(id, type){
       store.dispatch('pamis-productionTarget/fetchSpecByWorkcenter', id)
        .then(response => {
          if(response.data != null && response.data.length > 0){
            this.specList = response.data.filter(x=> x.isDelete == false);
            this.isSpec = true;

            if (type == 2 && this.currentProductionTarget.specificationID != null) {
              var specItem = response.data.find(x=>x.id === this.currentProductionTarget.specificationID);
              this.currentSpec = specItem;
            }
          } else {
            this.isSpec = false;
            this.specList = null;
          }
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Get Spec By Workcenter By Client Error',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        });
    },
    changeProductionLocation(){
      this.currentSpec = null;
      var id = this.currentWorkcenter != null ? this.currentWorkcenter.id : this.currentSite != null ? this.currentSite.id : 0;

      if (id > 0) {
        this.fetchSpecByWorkcenter(id, 1)
      }
    },
    changeProductionLocationType(){
      this.isSite = false;
      this.isWorkcenter = false;  
      this.isSpec = false;  
      this.currentWorkcenter = null;   
      this.currentSite = null;
      this.specList = null;
      this.currentSpec = null;

      if (this.currentproductionLocation.id == 1) {
        this.isSite = true;
      }else {
        this.isWorkcenter = true;
        this.workcenterList = this.workcenters.filter(x=>x.productionLocationTypeID == this.currentproductionLocation.id)
        this.workcenterList.sort((a, b) => Number(a.id) - Number(b.id));
      }
    },
    fetchKPINames() {
      this.kpiNames = [];
      this.$store.dispatch('pamis-productionTarget/fetchProductionKPINames')
        .then((result) => {
          if (result != null) {
            this.kpiNames =  result.data
          }
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Get KPI Names By Client Error',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        });
    },
    fetchProductionLocations() {
      this.productionLocations = [];
      this.$store.dispatch('pamis-productionTarget/fetchProductionLocationTypes')
        .then((result) => {
          if (result != null) {
            this.productionLocations =  result.data.filter(x=>x.isActive == true)
            this.productionLocations.sort((a, b) => Number(a.id) - Number(b.id));
          }
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Get Production Location By Client Error',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        });
    },
    fetchWorkCenters() {
      this.$store.dispatch('pamis-productionTarget/fetchWorkCenters')
        .then((result) => {
          if (result != null) {
            this.workcenters =  result.data.filter(x=>x.isDelete == false && x.isActive == true)
            this.workcenterList = result.data.filter(x=>x.isDelete == false && x.isActive == true)
            this.workcenterList.sort((a, b) => Number(a.id) - Number(b.id));
          }
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Get Workcenter By Client Error',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        });
    },
    fetchSites() {
      this.$store.dispatch('pamis-productionTarget/fetchSites')
        .then((result) => {
          if (result != null) {
            this.sites =  result.data;
            this.sites.sort((a, b) => Number(a.id) - Number(b.id));
          }
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Get Site By Client Error',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        });
    },
    deleteProductionTarget(){
       this.$store.dispatch('pamis-productionTarget/deleteProductionTarget', this.currentProductionTarget.id)
        .then((result) => {
          this.$emit('refetch-data')
          this.$emit('update:is-add-new-production-target-sidebar-active', false)
          this.notification()
        });
    },

    submitForm(){
      if (this.currentKpiName == null) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'KPI Name can not be null',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })

        return false;
      }

      if (this.currentproductionLocation == null) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Production Location can not be null',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })

        return false;
      } else {
        if (this.currentproductionLocation.id == 1) {
           if (this.currentSite== null) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Site can not be null',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })

              return false;
            }
        }else if (this.currentproductionLocation.id > 0) {
          if (this.currentWorkcenter == null) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: this.currentproductionLocation.name + ' can not be null',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })

              return false;
            }
        }
      }

      var process = "pamis-productionTarget/addProductionTarget";

      if(this.currentProductionTarget !=null &&  this.currentProductionTarget.id != null){
        process = "pamis-productionTarget/updateProductionTarget";
      } 

      this.currentProductionTarget.isActive = this.currentIsActive;
      this.currentProductionTarget.value = this.currentValue;
      this.currentProductionTarget.kpiName = this.currentKpiName;
      this.currentProductionTarget.productionLocationTypeID = this.currentproductionLocation.id;
      this.currentProductionTarget.productionLocationID = this.currentWorkcenter != null ? this.currentWorkcenter.id :
       this.currentSite != null ? this.currentSite.id : 0;
      this.currentProductionTarget.specificationID = this.currentSpec != null ? this.currentSpec.id : null;
       
      this.$store.dispatch(process, this.currentProductionTarget)
        .then((result) => {
          this.$emit('refetch-data')
          this.$emit('update:is-add-new-production-target-sidebar-active', false)
          this.notification()
        });

    },
    notification() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Process Successfully',
          icon: 'BellIcon',
        },
      })
    },
  },
  
  watch: {
    
     productionTargetdata:   {
        handler:function (n, o)   {
          if (n==null||n==undefined || n.selectedProductionTarget.id == null)
          {
            this.currentProductionTarget={
              Id:null,
              kpiName:null,
              value:null,
              productionLocationTypeID:null,
              productionLocationTypeName:null,
              productionLocationID:null,
              productionLocationName:null,
              isActive:null,
            }

            this.currentKpiName = null;
            this.currentValue = null;
            this.currentproductionLocation = null;
            this.isSite = false;
            this.isWorkcenter = false; 
            this.isSpec = false; 
            this.currentSite = null;
            this.isWorkcenter = false; 
            this.currentSpec= null;
          } else
          { 
            this.currentProductionTarget = n.selectedProductionTarget;
            this.currentIsActive = n.selectedProductionTarget.isActive;
            this.currentValue = n.selectedProductionTarget.value;

            if (this.currentProductionTarget.kpiName != null) {
              this.currentKpiName = this.currentProductionTarget.kpiName;
            }

            if (this.currentProductionTarget.productionLocationTypeID != null) {
              this.isSite = false;
              this.currentSite = null;
              this.isWorkcenter = false;
              this.currentWorkcenter = null;
              this.isSpec = false;
              this.currentSpec = null;

              var productionLocationType = this.productionLocations.find(x=>x.id === this.currentProductionTarget.productionLocationTypeID);
              this.currentproductionLocation = productionLocationType;

              if (this.currentProductionTarget.productionLocationTypeID == 1) {
                this.isSite = true;
                var site = this.sites.find(x=>x.id === this.currentProductionTarget.productionLocationID);
                this.currentSite = site;
              } else if(this.currentProductionTarget.productionLocationTypeID > 1 )  { 
                  this.isWorkcenter = true;
                  this.workcenterList = this.workcenters.filter(x=>x.productionLocationTypeID == this.currentproductionLocation.id)
                  this.workcenterList.sort((a, b) => Number(a.id) - Number(b.id));
                  var workcenter = this.workcenters.find(x=>x.id === this.currentProductionTarget.productionLocationID);
                  this.currentWorkcenter = workcenter;
              }

              var id = this.currentWorkcenter != null ? this.currentWorkcenter.id : this.currentSite != null ? this.currentSite.id : 0;

              if (id > 0) {
                this.fetchSpecByWorkcenter(id, 2)
              }
            }
          }
        },
        deep: true
    },
},
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewProductionTargetSidebarActive',
    event: 'update:is-add-new-production-target-sidebar-active',
  },
  props: {
    isAddNewProductionTargetSidebarActive: {
      type: Boolean,
      required: true,
    },
     actionType: {
      type: Number,
      required: true,
    },
  },
   
  data() {
    return {
      required,
      alphaNum,
      email,
      countries,
      currentWorkcenter : null,
      workcenters : [],
      workcenterList : [],
      isWorkcenter : false,
      currentSite : null,
      sites : [],
      isSite : false,
      productionLocations : [],
      kpiNames : [],
      currentKpiName : null,
      currentValue : null,
      currentproductionLocation : null,
      currentIsActive : true,
      currentProductionTarget:{
        Id:null,
        kpiName:null,
        value:null,
        isActive:null,
      },
      isSpec : false,
      specList : [],
      currentSpec : null,
    }
  },
   
  inject: ['productionTargetdata'],

  setup(props,  { emit }) {
    const blankProductionTargetData = {
      id:null,
      kpiName:null,
      value:null,
      isActive:null,
    }

    const productionTargetData = ref(JSON.parse(JSON.stringify(blankProductionTargetData)))
    const resetProductionTargetData = () => {
      productionTargetData.value = JSON.parse(JSON.stringify(blankProductionTargetData))
      emit('refetch-data')
      emit('update:is-add-new-production-target-sidebar-active', false)
    }
      
    const onSubmit = () => {
      store.dispatch('pamis-productionTarget/addProductionTarget', currentProductionTarget)
        .then(() => {
        })
    }
     
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetProductionTargetData)

    return {
      productionTargetData,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-productionTarget-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
