import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useProductionTargetList() {
  // Use toast
  const toast = useToast()
  const refProductionTargetListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'id', sortable: true },
    { key: 'kpiName', sortable: true },
    { key: 'value', sortable: true },
    { key: 'productionLocationTypeName', label: 'production Location Type', sortable: true },
    { key: 'productionLocationName', label: 'production Location Name', sortable: true },
    { key: 'spec', sortable: true },
    { key: 'isActive' },
    { key: 'actions' },
  ]
  const perPage = ref(10)
  const totalProductionTargets = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const row = ref([])
  const specs = ref([])
  const search = ref('')

  const dataMeta = computed(() => {
    const localItemsCount = refProductionTargetListTable.value ? refProductionTargetListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * currentPage.value,
      of: totalProductionTargets.value,
    }
  })

  const refetchData = () => {
    refProductionTargetListTable.value.refresh()
    GetSpecs()
  }

  watch([currentPage, perPage, search], () => {
    refetchData()
  })


  function GetSpecs() {
    store.dispatch('pamis-productionTarget/fetchSpecs')
      .then(response => {
        specs.value = response.data.filter(x=>x.isDelete == false && x.isActive == true);
        GetProductionTargets();
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching Product list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  function GetProductionTargets() {
    store.dispatch('pamis-productionTarget/fetchProductionTargets')
      .then(response => {
        const productResult = response.data.filter(x=>x.isDelete == false);
        row.value = productResult;
        totalProductionTargets.value = productResult.length;

        row.value.forEach(element => {
          const specList = specs.value.filter(x => x.id == element.specificationID)

          if (specList != null && specList.length > 0) {
            element.spec = specList[0].specNo;
          }
        })
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching Production Target list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  GetSpecs()


  return {
    row,
    tableColumns,
    perPage,
    currentPage,
    search,
    totalProductionTargets,
    dataMeta,
    perPageOptions,
    sortBy,
    isSortDirDesc,
    refProductionTargetListTable,
    refetchData
  }
}
