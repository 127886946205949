<template>
  <div>
    <productionTarget-add-new
      :is-add-new-production-target-sidebar-active.sync="isAddNewProductionTargetSidebarActive"
      :selectedProductionTarget="selectedProductionTarget"
      :actionType="actionType"
      @refetch-data="refetchData"
    />

    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="search"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
              <b-button
                variant="primary"
                @click="newProductionTarget"
              >
                <span class="text-nowrap">Add Production Target</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refProductionTargetListTable"
        class="position-relative"
        :items="row"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :current-page="currentPage"
        :per-page="perPage"
        :filter="search"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >

      <template #cell(isActive)="data">
        <b-badge :variant="isActiveVariant(data.item.isActive)">
            {{ data.item.isActive }}
          </b-badge>
      </template>

        <template #cell(actions)="data">
          <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item @click="changeProductionTarget(data.item,1)">
                <feather-icon
                  icon="EditIcon"
                  class="mr-50"
                />
                <span>{{$t("Edit")}}</span>
              </b-dropdown-item>
              <b-dropdown-item  @click="changeProductionTarget(data.item,2)">
                <feather-icon
                  icon="TrashIcon"
                  class="mr-50"
                />
                <span>{{$t("Delete")}}</span>
              </b-dropdown-item>
            </b-dropdown>
        </template> 

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalProductionTargets"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import useProductionTargetList from './useproductionTargetList'
import productionTargetStoreModule from '../productionTargetStoreModule'
import ProductionTargetAddNew from './ProductionTargetAddNew.vue'
import moment from 'moment'

export default {
   data() {
    return {
      selectedProductionTarget:null,  
      search:'',
      actionType : 0
    }
  },
    provide() {
        const productionTargetdata = {}
        Object.defineProperty(productionTargetdata, "selectedProductionTarget", {
            enumerable: true,
            get: () => this.selectedProductionTarget,
        })

        return {
            productionTargetdata,
        }
    },
  computed: {
    isActiveVariant() {
        const priorityColor = {
          false: 'light-danger',
          true: 'light-success'
        }

        return priority => priorityColor[priority]
      },
  },
  methods:{
    changeProductionTarget(e,actionType)
    {
      this.actionType = actionType;
      this.selectedProductionTarget=e;
      this.isAddNewProductionTargetSidebarActive=true;
    },
    newProductionTarget()
    {
      this.selectedProductionTarget={
        id:null,
        kpiName:null,
        value:null,
        productionLocationTypeID:null,
        productionLocationTypeName:null,
        productionLocationID:null,
        productionLocationName:null,
        isActive:true
      };

      this.actionType = 0;
      this.isAddNewProductionTargetSidebarActive=true;
    },
    format_date(value){
      if(value){
          var myDate = new Date('0001-01-01T00:00:00Z');
          var dateItem = moment(value);

          if(dateItem > myDate){
              return moment(value).locale("en").format("LLL");
          }
      }
    }
  },
  components: {
    ProductionTargetAddNew,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'pamis-productionTarget'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, productionTargetStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const isAddNewProductionTargetSidebarActive = ref(false)

    const {
      row,
      tableColumns,
      perPage,
      currentPage,
      totalProductionTargets,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refProductionTargetListTable,
      refetchData,
      search
    } = useProductionTargetList()

    return {
      isAddNewProductionTargetSidebarActive,
      row,
      tableColumns,
      perPage,
      currentPage,
      totalProductionTargets,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refProductionTargetListTable,
      refetchData,
      search,
      // Filter
      avatarText,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
