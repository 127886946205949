import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchProductionTargets(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('pamis/admin/organization/ProductionTarget')
          .then((response)=> { 

            return resolve(response)})
          .catch(error => reject(error))
      })
    },
    fetchProductionLocationTypes(ctx) {
        return new Promise((resolve, reject) => {
          axios
            .get('pamis/admin/organization/ProductionLocationType')
            .then((response)=> { 
  
              return resolve(response)})
            .catch(error => reject(error))
        })
    },
    fetchSites(ctx) {
        return new Promise((resolve, reject) => {
          axios
            .get('pamis/admin/organization/Site')
            .then((response)=> { 
  
              return resolve(response)})
            .catch(error => reject(error))
        })
    },
    fetchWorkCenters(ctx) {
        return new Promise((resolve, reject) => {
          axios
            .get('pamis/admin/organization/WorkCenter')
            .then((response)=> { 
  
              return resolve(response)})
            .catch(error => reject(error))
        })
    },
    fetchProductionKPINames(ctx) {
        return new Promise((resolve, reject) => {
          axios
            .get('pamis/admin/organization/ProductionTarget/ProductionKPINames')
            .then((response)=> { 
  
              return resolve(response)})
            .catch(error => reject(error))
        })
    },
    addProductionTarget(ctx, formData) {
      return new Promise((resolve, reject) => {
        axios
          .post('pamis/admin/organization/ProductionTarget/Create', formData)
          .then(({ data }) => {
            resolve(data)
          })
          .catch(error => reject(error))
      })
    },
    updateProductionTarget(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .put(`pamis/admin/organization/ProductionTarget/Update?id=${params.id}`, params)
          .then(({ data }) => {
            resolve(data)
          })
          .catch(error => reject(error))
      })
    },
    deleteProductionTarget(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`pamis/admin/organization/ProductionTarget/Delete?id=${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchSpecs(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('pamis/admin/parameter/Specification/Specs')
          .then((response)=> { 

            return resolve(response)})
          .catch(error => reject(error))
      })
    },
    fetchSpecByWorkcenter(ctx,  id) {
      return new Promise((resolve, reject) => {
        axios
          .get('pamis/admin/parameter/Specification/SpecsByWorkCenterId?WorkCenterId=' + id)
          .then((response)=> { 

            return resolve(response)})
          .catch(error => reject(error))
      })
    },
  },
}
